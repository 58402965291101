// import Head from 'next/head';
import _ from "lodash";
import {
  getIndividualPageData,
  getPageInfo,
  parseParagraphReact
} from "@/api/global";
import Typography from "@material-ui/core/Typography";
import dynamic from "next/dynamic";
const SlickComponentLibs = dynamic(() => import('@/components/CarouselComponentSlickStyles'));

export async function getServerSideProps(context) {
  let initialProps = {
    statusCode: 200,
  };

  let data = null;
  // Potnets Homepage NID in Drupal:
  const nid = 3623;

  try {
    if (nid && !isNaN(nid)) {
      // Listing data about currently opened listing.
      data = await getPageInfo(`/node/${nid}`);

      if (data && data.page?.jsonapi) {
        const node_data = await getIndividualPageData(data.page.jsonapi.individual);
        if (node_data) {
          initialProps = {...initialProps, ...node_data};
        }
      }

      // Merge initially defined props with response from the backend.
      initialProps = {...initialProps, ...data};
    }
    if (!data) {
      return {
        notFound: true,
      }
    }
  }
  catch (e) {
    console.warn('HOMEPAGE retrieval error:', e);
    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;

    // In case of Server Side Rendering, we want the server to throw the
    // correct error code.
    // if (res) {
    //   res.statusCode = 500;
    // }
  }

  return {
    props: {...initialProps}, // will be passed to the page component as props
    // notFound: true, // https://github.com/vercel/next.js/discussions/11862,
    // https://stackoverflow.com/questions/67946219/throw-new-errorfailed-to-load-static-props-when-setting-fallback-true-i/67946220#67946220
  }
}

export default function Home(props) {
  let node_data, body, body_json, para, paraTop;
  if (typeof document !== 'undefined') {
    document?.body?.classList?.add('homepage');
  }

  if (props) {
    node_data = props?.page_data;
    if (node_data) {
      ({body, body_json, para, paraTop} = node_data);
    }
  }

  return (
    <div>
      <main>
        <SlickComponentLibs/>
        {!node_data &&
        <>
          <Typography component={`h1`} className={`page-title`} variant="h1">Welcome to Potnets!</Typography>
          <Typography component={`p`} variant="p">As our client, you receive the benefit of a full team of real
            estate experts.
            We have full-time staff, agents, and property managers available to
            assist you in any
            type of real estate transaction and we’re ready to help you achieve
            your real estate goals.</Typography>
        </>
        }
        {paraTop?.length > 0 &&
        <Typography paragraph className={`alignfull region-top`} component={`div`}>
          {
            paraTop.map((para_ent, key) => {
              return parseParagraphReact(key, para_ent);
            })
          }
        </Typography>
        }
        {body?.length > 0 && _.isEmpty(body_json) &&
          <Typography paragraph component={`div`}>
            <Typography component={`div`} dangerouslySetInnerHTML={{__html: body}}></Typography>
          </Typography>
        }
        {para?.length > 0 &&
        <Typography paragraph component={`div`}>
          {
            para.map((para_ent, key) => {
              return parseParagraphReact(key, para_ent);
            })
          }
        </Typography>
        }
      </main>
    </div>
  )
}
